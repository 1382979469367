export enum UserRoleEnum {
    USER = 'user',
    ADMIN = 'admin',
    OFFICER = 'officer',
    REQUESTER = 'requester',
    APPROVER = 'approver',
    SYSTEM_ADMIN = 'systemAdmin',
}

export enum UserRoleEnumInAdmin {
    USER = 'user',
    ADMIN = 'admin',
    OFFICER = 'officer',
    REQUESTER = 'requester',
    // APPROVER = 'approver',
    // SYSTEM_ADMIN = 'systemAdmin',
}
